<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neue Neuigkeit">
      <news-form
        :news-item="newsItem"
        submit-label="Eintrag anlegen"
        :form-submitted="saveNewsItem"
        mode="new"
        @image-uploaded="addImage"
      ></news-form>
    </page-content>
  </div>
</template>

<script>
import Vue from "vue"
import { startOfToday } from "date-fns"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import NewsForm from "@/components/forms/NewsForm"
import NewsRepository from "@/repositories/news_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Neuigkeit erstellen"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    NewsForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      newsItem: {
        content: "",
        published_at: startOfToday()
      }
    }
  },
  computed: {
    persisted() {
      return this.newsItem.hasOwnProperty("id")
    }
  },
  async created() {},
  methods: {
    async saveNewsItem(newsItem) {
      if (this.persisted) {
        await NewsRepository.update(newsItem.id, newsItem)
      } else {
        await NewsRepository.create(newsItem)
      }

      this.$router.push({ name: "NewsPage" })
    },
    async addImage({ newsItem, signed_id, command }) {
      try {
        if (this.persisted) {
          newsItem.images.push(signed_id)
          let response = await NewsRepository.update(newsItem.id, newsItem)
          Vue.set(this.newsItem, "images", response.news_item.images)
          Vue.set(
            this.newsItem,
            "image_attachments",
            response.news_item.image_attachments
          )
          let attachment = this.newsItem.image_attachments.find(
            a => a.blob_signed_id === signed_id
          )
          command({
            src: `${process.env.VUE_APP_ROOT_API}/regions/image_proxy/${attachment.id}`
          })
        } else {
          newsItem.images = []
          newsItem.images.push(signed_id)
          let response = await NewsRepository.create(newsItem)
          Vue.set(this.newsItem, "id", response.news_item.id)
          Vue.set(this.newsItem, "images", response.news_item.images)
          Vue.set(
            this.newsItem,
            "image_attachments",
            response.news_item.image_attachments
          )
          let attachment = this.newsItem.image_attachments.find(
            a => a.blob_signed_id === signed_id
          )
          command({
            src: `${process.env.VUE_APP_ROOT_API}/regions/image_proxy/${attachment.id}`
          })
        }
      } catch (error) {
        this.handleApiError(error)
      }
    }
  }
}
</script>
